<template>
  <v-row class="pa-5 align-center">
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12" class="text-h6 black--text pb-0">
          Formulários de anamnese
        </v-col>
        <v-col cols="12" class="subtitle-2 grey--text pt-0">
          Lista de formulários criados
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6" class="d-flex justify-end">
      <v-btn
        @click="
          $router.push({
            name: 'AdminAnamnesisCreate',
          })
        "
        class="primary"
      >
        <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
        Novo formulário
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card outlined class="elevation-0 rounded-0">
        <v-card-title class="mb-2">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Buscar por nome"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="anamnesis"
          :search="search"
          :loading="!anamnesis"
          item-key="id"
          :custom-filter="filterOnlyText"
        >
          <template v-slot:[`item.name`]="{ item }">
            <div
              @click="
                $router.push({
                  name: 'AdminAnamnesisEdit',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <div
              @click="
                $router.push({
                  name: 'AdminAnamnesisEdit',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.date }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              height="30"
              dense
              class="primary caption"
              @click="
                $router.push({
                  name: 'AdminAnamnesisEdit',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
            >
              <v-icon small class="pr-2">mdi-pencil</v-icon>
              Editar fomulário
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    search: "",
    anamnesis: [],
  }),
  mounted() {
    this.showAnamnesis();
  },
  methods: {
    async showAnamnesis() {
      try {
        const response = await this.$axios("forms");

        if (response.data) {
          response.data.map((form) => {
            this.anamnesis = [
              {
                id: form.id,
                name: form.name,
                required: form.required,
                config: form.config,
                date: this.formatLastUpdate(form.created_at),
                form_items_attributes: form.form_items,
              },
              ...this.anamnesis,
            ];
          });
        }
      } catch (error) {
        console.log("Show anamnesis error: ", error);
      }
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    filterOnlyText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().toString().indexOf(search.toLowerCase()) !== -1
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome do formulário",
          value: "name",
          width: 250,
          align: "center",
        },
        { text: "Data de criação", value: "date", width: 250, align: "center" },
        { text: "Ação", value: "action", width: 250, align: "center" },
      ];
    },
  },
};
</script>

<style></style>
