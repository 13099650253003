var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-5 align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-h6 black--text pb-0",attrs:{"cols":"12"}},[_vm._v(" Formulários de anamnese ")]),_c('v-col',{staticClass:"subtitle-2 grey--text pt-0",attrs:{"cols":"12"}},[_vm._v(" Lista de formulários criados ")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.$router.push({
          name: 'AdminAnamnesisCreate',
        })}}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Novo formulário ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0 rounded-0",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"mb-2"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Buscar por nome","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.anamnesis,"search":_vm.search,"loading":!_vm.anamnesis,"item-key":"id","custom-filter":_vm.filterOnlyText},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'AdminAnamnesisEdit',
                params: {
                  id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'AdminAnamnesisEdit',
                params: {
                  id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"primary caption",attrs:{"height":"30","dense":""},on:{"click":function($event){return _vm.$router.push({
                name: 'AdminAnamnesisEdit',
                params: {
                  id: item.id,
                  data: item,
                },
              })}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" Editar fomulário ")],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }